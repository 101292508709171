import { RECEIVE_YOUTUBE } from '../actions';

export const DEFAULT_YOUTUBE_ID = 'none';

export const DEFAULT_YOUTUBE_JSON = {
    youtube: []
};

let defaultState = {
    [DEFAULT_YOUTUBE_ID]: DEFAULT_YOUTUBE_JSON
};

export default function archive(state = defaultState, action) {
    switch(action.type) {
        case RECEIVE_YOUTUBE:
            return Object.assign({}, state, {
                [action.payload.slug]: action.payload.youtube
            });
        default:
            return state;
    }
}
