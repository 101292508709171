import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import configureStore from './store/configureStore';

import Nav from './Nav';
import Home from './Home';
import Footer from './Footer';
import Player from './Player';

const store = configureStore();

ReactGA.initialize('UA-6658924-1');

function logPageView() {
  ReactGA.set({ page: window.location.pathname + window.location.search });
  ReactGA.pageview(window.location.pathname + window.location.search);
}

class App extends React.Component {
  render() { 
    return (
      <Provider store={store}>
          <BrowserRouter onUpdate={()=>{ window.scrollTo(0, 0); logPageView(); }} >
            <div>
                <Nav/>
                <div>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route component={Home} />
                        <Route onEnter={() => window.location.reload()} />
                    </Switch>
                    <Footer/>
                </div>      
                <Player />
            </div>
          </BrowserRouter>
      </Provider>
    );
  }
}

export default App;