import React from 'react';

import logoNetworkImage from './img/logo/network.png';

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">    

                            <div className="row">
                                <div className="col-xl-4 col-lg-6 col-sm-8 offset-xl-4 offset-lg-3 offset-sm-2">
                                    <div className="podcast-heading">
                                        <img src={logoNetworkImage} alt="Discussing Network"/>              
                                     </div>
                                </div>
                                <div className="col-lg-12 d-sm-none d-md-block">
                                    <div className="site-heading">
                                        <h2>Let’s work together!</h2>
                                        <div className="subheading">
                                            <a href="mailto:hosts@discussingnetwork.com?subject=Discussing%20Network%20Inquiry" rel="noopener noreferrer" target="_blank">hosts@discussingnetwork.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr /> 
                            <ul className="hosts">
                                <li><a href="https://www.discussingwho.com" target="_blank" rel="noopener noreferrer" title="Twitter"><img src={ 'http://twivatar.glitch.me/discussingwho' } alt=""/><br /><em>Discussing Who</em></a></li>
                                <li><a href="https://www.discussingcomics.com" target="_blank" rel="noopener noreferrer" title="Twitter"><img src={ 'http://twivatar.glitch.me/discussingcomix' } alt=""/><br /><em>Discussing Comics</em></a></li>
                                <li><a href="https://www.discussingtrek.com" target="_blank" rel="noopener noreferrer" title="Twitter"><img src={ 'http://twivatar.glitch.me/discussingtrek' } alt=""/><br /><em>Discussing Trek</em></a></li>
                                <li><a href="https://www.techpedition.com" target="_blank" rel="noopener noreferrer" title="Twitter"><img src={ 'http://twivatar.glitch.me/techpedition' } alt=""/><br /><em>Techpedition</em></a></li>
                                <li><a href="https://anchor.fm/extremely-geekd-out/" target="_blank" rel="noopener noreferrer" title="Twitter"><img src={ 'http://twivatar.glitch.me/ego_podcast' } alt=""/><br /><em>Extremely Geek'd Out</em></a></li>
                                <li><a href="https://anchor.fm/fandomsquadpodcast/" target="_blank" rel="noopener noreferrer" title="Twitter"><img src={ 'http://twivatar.glitch.me/discussingnet' } alt=""/><br /><em>Fandom Squad</em></a></li>
                                <li><a href="https://terminus.libsyn.com/" target="_blank" rel="noopener noreferrer" title="Twitter"><img src={ 'http://twivatar.glitch.me/terminuscast' } alt=""/><br /><em>Terminus</em></a></li>
                            </ul>

                            <p className="copyright">COPYRIGHT 2008-2020 Discussing Network, LLC. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        );

    }
}

export default Footer;
