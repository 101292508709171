import { showData } from './helperData'

export function getGuid() {
  function s4() { return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1); }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function getShowData(slug) {   
  if(slug === 'discussing-network')
    return showData.discussingNetwork;
  else if(slug === 'discussing-who')
    return showData.discussingWho;
  else if(slug === 'discussing-comics')
    return showData.discussingComics;
  else if(slug === 'discussing-trek')
    return showData.discussingTrek;
  else if(slug === 'techpedition')
    return showData.techpedition;
  else if(slug === 'extremely-geekd-out')
    return showData.extreme;
  else if(slug === 'terminus')
      return showData.terminus;
  else 
    return showData.default;
}

// export function htmlDecode(input){
//   var e = document.createElement('textarea');
//   e.innerHTML = input;
//   return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
// }
