import heroNetworkImage from '../img/hero/network.jpg';
import heroNetworkImage1 from '../img/hero/network1.jpg';
import heroNetworkImage2 from '../img/hero/network2.jpg';

import heroWhoImage from '../img/hero/who.jpg';
import heroWhoImage1 from '../img/hero/who1.jpg';
import heroWhoImage2 from '../img/hero/who2.jpg';
import heroWhoImage3 from '../img/hero/who3.jpg';
import heroWhoImage4 from '../img/hero/who4.jpg';

import heroTrekImage from '../img/hero/trek.jpg';
import heroTrekImage1 from '../img/hero/trek1.jpg';
import heroTrekImage2 from '../img/hero/trek2.jpg';
import heroTrekImage3 from '../img/hero/trek3.jpg';
import heroTrekImage4 from '../img/hero/trek4.jpg';

import logoNetworkImage from '../img/logo/network.v2.png';
import logoWhoImage from '../img/logo/who.png';
import logoTrekImage from '../img/logo/trek.v2.png';

export const showData = { 
    discussingNetwork: { 
        name: "Discussig Who",
        logo: logoNetworkImage, 
        taglineHeading: 'New episodes weekly!',
        tagline: 'Discussing Network features interviews, reviews, and news, with a focus on Doctor Who, Star Trek, and Comics. We also tend to cover other geek-focused topics that we may find interesting. Discussing Network, covering all things geek! Subscribe to this super-feed to catch every episode.', 
        subscribe: 'Subscribe to the super-feed to get all episodes!',
        applePodcasts: 'https://podcasts.apple.com/us/podcast/discussing-network-doctor-who-star-trek-comics/id1459210263',
        googlePodcasts: 'https://podcasts.google.com/?feed=aHR0cDovL2ZlZWRzLnNvdW5kY2xvdWQuY29tL3VzZXJzL3NvdW5kY2xvdWQ6dXNlcnM6NTY2MTQ1OTA5L3NvdW5kcy5yc3M',
        spotify: 'https://open.spotify.com/show/2bjE1Go7zCpco4v0Asn1BR',
        youtube: 'https://www.youtube.com/discussingnetwork',
        rss: 'https://www.discussingnetwork.com/feed/podcast/',
        more: 'https://www.discussingwho.com/subscribe/',
        facebook: 'https://www.facebook.com/groups/discussingnetwork',
        twitter: 'https://twitter.com/discussingnet',
        email: 'hosts@discussingnetwork.com',
        class: 'network',
        hosts: [ 
          { name: 'Kyle Jones', handle: 'KyleMJ6977', title: 'Host/Co-Founder' },
          { name: 'Lee Shackleford', handle: 'LeeShackleford', title: 'Host/Co-Founder' },
          { name: 'Clarence Brown', handle: 'wudanbal', title: 'Host/Co-Founder' }
        ],
        heroImages: [
          heroNetworkImage,
          heroNetworkImage1,
          heroNetworkImage2
      ]
    },
    discussingWho: { 
        name: "Discussig Who",
        logo: logoWhoImage, 
        taglineHeading: 'Reviewing All of Time and Space! Each and every week!',
        tagline: 'Discussing Who features interviews, reviews, news, and more ' + 
        ' about our favorite Time Lord. It’s an adventure in space and time, and you’re invited!', 
        subscribe: 'Dont miss an episode, be sure to subscribe to get weekly episodes!',
        applePodcasts: 'https://itunes.apple.com/us/podcast/discussing-who/id1088690572?mt=2&ls=1',
        googlePodcasts: 'https://www.google.com/podcasts?feed=aHR0cDovL3d3dy5kaXNjdXNzaW5nd2hvLmNvbS9mZWVkL3BvZGNhc3Q%3D',
        spotify: 'https://open.spotify.com/show/1hTblZsEMZ2NqbnjMRrU61',
        youtube: 'https://www.youtube.com/discussingnetwork',
        rss: 'https://www.discussingwho.com/feed/podcast',
        more: 'https://www.discussingwho.com/subscribe/',
        facebook: 'https://www.facebook.com/discussingwho',
        twitter: 'https://twitter.com/discussingwho',
        email: 'hosts@discussingnetwork.com',
        class: 'who',
        hosts: [ 
          { name: 'Kyle Jones', handle: 'KyleMJ6977', title: 'Showrunner' },
          { name: 'Lee Shackleford', handle: 'LeeShackleford', title: 'Host' },
          { name: 'Clarence Brown', handle: 'wudanbal', title: 'Host' }
        ],
        heroImages: [
          heroWhoImage,
          heroWhoImage1,
          heroWhoImage2,
          heroWhoImage3,
          heroWhoImage4
        ]
    },
    discussingTrek: { 
        name: "Discussig Trek",
        logo: logoTrekImage, 
        taglineHeading: 'Discussing all of the Star Trek Universe and More',
        tagline: 'Discussing Trek is an unofficial podcast about the CBS All-Access Star Trek series. We review each episode in somewhat excessive detail, in ' + 
            'addition to talking all things Trek.', 
        subscribe: 'Dont miss an episode, be sure to subscribe to get bi-weekly episodes!',
        applePodcasts: 'https://itunes.apple.com/us/podcast/std-a-star-trek-discovery-podcast/id1277153604?mt=2&ls=1',
        googlePodcasts: 'https://www.google.com/podcasts?feed=aHR0cDovL3d3dy5kaXNjdXNzaW5ndHJlay5jb20vZmVlZC9wb2RjYXN0',
        spotify: 'https://open.spotify.com/show/1bfI1F2jWkcK6Ca8YjbJMv',
        youtube: 'https://www.youtube.com/channel/UCiBWnT7aa2tl6iqaCRp0V_g',
        rss: 'https://www.discussingtrek.com/feed/podcast',
        more: 'https://www.discussingtrek.com/subscribe/',
        facebook: 'https://www.facebook.com/discussingtrek',
        twitter: 'https://www.twitter.com/discussingtrek',
        email: 'fans@discussingtrek.com',
        class: 'trek',
        hosts: [ 
            { name: 'Clarence Brown', handle: 'wudanbal', title: 'Showrunner' },
            { name: 'Kyle Jones', handle: 'KyleMJ6977', title: 'Host'  },
            { name: 'Kary Brown', handle: 'mrbegoma', title: 'Host'  },
            { name: 'Jonathan Shorts', handle: 'jshorts24', title: 'Host'  },
            { name: 'Jeremy Barrow', handle: 'jerbearthe1st', title: 'Host'  },
        ],
        heroImages: [
          heroTrekImage,
          heroTrekImage1,
          heroTrekImage2,
          heroTrekImage3,
          heroTrekImage4
        ]
    },
    default: { 
        name: '',
        logo: null, 
        tagline: '', 
        applePodcasts: '',
        googlePodcasts: '',
        spotify: '',
        youtube: '',
        rss: '',
        more: '',
        facebook: '',
        twitter: '',
        email: '',
        class: '',
        hosts: [ ],
        heroImages: []
    }  
  ,
};
