import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Nav extends Component {
    constructor(props){
        super(props);
        this.state = { isOpen: false };        
        this.handleClick = this.handleClick.bind(this);
    }
        
    handleClick() {
        this.setState({ isOpen: false });
    }

    render() {
        return (  
            <nav className="navbar navbar-expand-lg navbar-custom">  
                <div className="navbar-header mr-auto">
                    <Link className="navbar-brand" to="/">Discussing Network</Link> 
                </div>
                <div className="collapse navbar-collapse">
                    <ul className="nav navbar-nav ml-auto my-2">
                        <li className="nav-item">
                            <a href="https://www.discussingwho.com" target="_blank" rel="noopener noreferrer" title="Discusing Who">Discusing Who</a>
                            <a href="https://www.discussingtrek.com" target="_blank" rel="noopener noreferrer" title="Discussing Trek">Discusing Trek</a>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default Nav;
