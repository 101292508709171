import React from 'react';
import { connect } from 'react-redux';

import { getGuid } from '../js/helpers';
import { fetchPodcast } from '../actions';
import { DEFAULT_EPISODE_JSON } from '../reducers/episode';
import { DEFAULT_PODCAST_JSON } from '../reducers/podcast';

import HeroPostPreview from './HeroPostPreview';

class HeroPostPreviews extends React.Component {
    componentDidMount() {
        this.props.fetchPodcast("4c782b5b-3142-45b2-98b3-c5b61bac8e96");
    }

    render() {
        const { podcast } = this.props;
        
        var episodes = [];

        episodes.push(Array.from(podcast).filter(function(item){ return item.title.startsWith('Discussing Trek'); }) || DEFAULT_PODCAST_JSON);
        episodes.push(Array.from(podcast).filter(function(item){ return item.title.startsWith('Discussing Who'); }) || DEFAULT_PODCAST_JSON);

        console.log(Array.from(podcast).filter(function(item){ return item.title.startsWith('Discussing Trek'); }));

        episodes.sort(function(a, b){ 
            var first = a[0] || DEFAULT_EPISODE_JSON;
            var second = b[0] || DEFAULT_EPISODE_JSON;
            return  new Date(first.pubDate).getTime() > new Date(second.pubDate).getTime() ? -1 : 1;
        });

        return (
            <>
                <HeroPostPreview key={getGuid()} episodes={episodes[0]}/>  
                <HeroPostPreview key={getGuid()} episodes={episodes[1]}/> 
            </>
        );
    }
}

function mapStateToProps(state) { 
    return { podcast: state.podcast["4c782b5b-3142-45b2-98b3-c5b61bac8e96"] || DEFAULT_PODCAST_JSON };
}

export default connect( mapStateToProps, { fetchPodcast } )(HeroPostPreviews)
