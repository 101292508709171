import { RECEIVE_PODCAST } from '../actions';

export const DEFAULT_PODCAST_ID = 'none';

export const DEFAULT_PODCAST_JSON = {
    podcast: []
};

let defaultState = {
    [DEFAULT_PODCAST_ID]: DEFAULT_PODCAST_JSON
};

export default function archive(state = defaultState, action) {
    switch(action.type) {
        case RECEIVE_PODCAST:
            return Object.assign({}, state, {
                [action.payload.slug]: action.payload.podcast
            });
        default:
            return state;
    }
}
