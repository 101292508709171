import React from 'react';
import ReactDOMClient from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './css/index.scss';

import App from './App';

ReactDOMClient
    .hydrateRoot(document.getElementById("root"), <App />);
