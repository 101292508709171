import fetch from 'isomorphic-fetch';

export const RECEIVE_EPISODE = 'RECEIVE_EPISODE';
export const RECEIVE_PODCAST = 'RECEIVE_PODCAST';
export const RECEIVE_VIDEO = 'RECEIVE_VIDEO';
export const RECEIVE_YOUTUBE = 'RECEIVE_YOUTUBE';

export function receiveEpisode(data) {
    return {
        type: RECEIVE_EPISODE,
        payload: { 
            episode: data 
        }
    };
}

export function receivePodcast(slug, data) {
    return {
        type: RECEIVE_PODCAST,
        payload: {
            slug: slug,
            podcast: data,
        }
    };
}

export function fetchPodcast(slug) {
    return function (dispatch) {  
        var endpoint = 'https://www.discussingnetwork.com/feed/podcast/rss.json';
        
        return fetch(endpoint)
            .then(response => Promise.all([response.json()]))
            .then(function(data) { 
                dispatch(receivePodcast(slug, data[0].rss.channel.item));
            });
    }
}

export function receiveVideo(data) {
    return {
        type: RECEIVE_VIDEO,
        payload: { 
            video: data 
        }
    };
}

export function receiveYoutube(slug, data) {
    return {
        type: RECEIVE_YOUTUBE,
        payload: {
            slug: slug,
            youtube: data,
        }
    };
}

export function fetchYoutube(slug) {
    return function (dispatch) {  
        var endpoint = 'https://www.discussingnetwork.com/feed/video/video-rss.json';
        
        return fetch(endpoint)
            .then(response => Promise.all([response.json()]))
            .then(function(data) { 
                dispatch(receiveYoutube(slug, data[0].rss.channel.item));
            });
    }
}
