import React from 'react';
import { connect } from 'react-redux';
import { receiveEpisode } from './actions';
import { DEFAULT_EPISODE_JSON } from './reducers/episode';
import ReactJkMusicPlayer from "react-jinke-music-player";
import "./css/react-jinke-music-player/index.scss";
import { saveAs } from 'file-saver';

const options = {
    audioLists: [],
    theme: 'dark',
    glassBg: true,
    defaultPosition: {
        right: 100,
        bottom: 100,
    },
    mode: 'mini',
    autoPlay: true,
    toggleMode: true,
    showMiniModeCover: true,
    showMiniProcessBar: false,
    showMediaSession: true,
    showProgressLoadBar: true,
    showPlay: true,
    showReload: true,
    showDownload: true,
    showPlayMode: true,
    showThemeSwitch: false,
    showLyric: false,
    showDestroy: false, 
    responsive: false,

    customDownloader(downloadInfo) {
        console.log(downloadInfo.src)
        console.log(downloadInfo.filename)
        console.log(downloadInfo.mimeType)

        saveAs(downloadInfo.src);
    },
}

class Player extends React.Component {
    constructor(props) {
        super(props);
        this.audio = {};
    
        this.state = {
            unmount: false,
            params: {
                ...options,
                getAudioInstance: (audio) => {
                    this.audio = audio
                },
            },
        }        
    }
    
    updateParams(params) {
        const data = {
            ...this.state.params,
            ...params,
        }
        this.setState({
            params: data,
        })
    }
    
    render() {
        
        const { params } = this.state
        const { episode } = this.props;
                   
        if(episode.enclosure !== '') {

            // convert title to array object
            var episodeTitleArray = episode.title.split(':');
            
            // remove first element in array object
            episodeTitleArray.shift();
            
            // determine show name
            var showName = 
                episode.title.startsWith('Discussing Comics') ? 'Discussing Comics' :
                episode.title.startsWith('Discussing Trek') ? 'Discussing Trek' :
                episode.title.startsWith('Discussing Who') ? 'Discussing Who' :
                episode.title.startsWith('Techpedition') ? 'Techpedition' :
                episode.title.startsWith('Extremely Geek\'d Out') ? 'Extremely Geek\'d Out' : 
                episode.title.startsWith('Fandom Squad') ? 'Fandom Squad' : 
                episode.title.startsWith('Terminus') ? 'Terminus' : '';
        
            var currentEpisode = {
                name: episode.title.match(':') ? episodeTitleArray.join(':') : episode.title,
                singer: showName, 
                cover: episode['itunes:image']['@href'],
                musicSrc: episode.enclosure['@url']
            }

            if(params.audioLists.filter(function(item) { return item.musicSrc === currentEpisode.musicSrc; }).length === 0) {
                this.updateParams({
                    clearPriorAudioLists: true,
                    audioLists: [
                        ...params.audioLists, 
                        currentEpisode,
                    ]
                });
            }
            else if(params.audioLists.filter(function(item) { return item.musicSrc === currentEpisode.musicSrc; }).length === 1) {

                var index = params.audioLists.indexOf(
                    params.audioLists.filter(function(item) { return item.musicSrc === currentEpisode.musicSrc; })[0]
                );

                if(params.playIndex !== index) {
                    this.updateParams({
                        playIndex: index
                    });
                }
            }
        }
    
        return (
            <ReactJkMusicPlayer {...params} />
        );
    }
}

function mapStateToProps(state) {
    return { episode: state.episode || DEFAULT_EPISODE_JSON };
}

export default connect(mapStateToProps, { receiveEpisode } )(Player);
