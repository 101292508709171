import React from 'react';
import { getGuid, getShowData } from '../js/helpers'

class HeroHeader extends React.Component {
    render() {
        var showData = getShowData(this.props.showSlug);
        var backgroundImage = showData.heroImages[Math.floor(Math.random() * showData.heroImages.length)];
        var backgroundStyle = { backgroundImage: 'url(' + backgroundImage + ')' };
        
        var hostsMarkup = showData.hosts.map(host => {
            return (             
                <div  key={getGuid()} className="col-lg-4 d-none d-lg-block">    
                    <a href={ 'https://twitter.com/' + host.handle }target="_blank" rel="noopener noreferrer" title="Twitter"><img src={ 'http://twivatar.glitch.me/' + host.handle } alt=""/></a>
                    <em>{ host.title }</em><br/>
                    { host.name }<br/>
                    <a href={ 'https://twitter.com/' + host.handle } target="_blank" rel="noopener noreferrer" title="Twitter">@{ host.handle }</a>
                </div>
            );
        });

        return (
            <div className="intro-header">
                <div className="background" style={backgroundStyle}></div>
                <div className="background-overlay"></div>
                <div className="container">
                    <div className={'row ' + showData.class + ' hero'}>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                            <div className="podcast-heading">
                                <img src={showData.logo} alt={showData.name}/>      
                                <div className="subtitle">{showData.subscribe}</div>   
                                <div className="subscribe">											
                                    <a href={showData.applePodcasts} target="_blank" rel="noopener noreferrer" title="Apple Podcasts"><i className="fa fa-apple"></i></a> 
                                    <a href={showData.spotify} target="_blank" rel="noopener noreferrer"title="Spotify"><i className="fa fa-spotify"></i></a>                                   
                                    <a href={showData.googlePodcasts} target="_blank" rel="noopener noreferrer"title="Google Podcasts"><i className="fa fa-google"></i></a>
                                    <a href={showData.youtube} target="_blank" rel="noopener noreferrer"title="YouTube"><i className="fa fa-youtube"></i></a>
                                    <a href={showData.rss} target="_blank" rel="noopener noreferrer"title="Rss Feed"><i className="fa fa-rss"></i></a>						
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="site-heading">
                                <h2>{showData.taglineHeading}</h2>
                                <div className="subheading">{showData.tagline}</div>
                                <div className="subheading">
                                    <a href={ 'mailto:' + showData.email + '?subject=' + showData.name + '%20Fan%20Mail'} rel="noopener noreferrer" target="_blank">{showData.email}</a>
                                </div>                                    
                                <div className="row d-flex justify-content-center hosts">
                                    { hostsMarkup }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeroHeader;
