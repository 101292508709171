import { RECEIVE_EPISODE } from '../actions';

export const DEFAULT_EPISODE_JSON = {
    src: '',
    title: '',
    subtitle: '',
    image: '',
    overlay: '',
    'itunes:image': '',
    link: '',
    enclosure: ''
};

let defaultState = DEFAULT_EPISODE_JSON;

export default function episode(state = defaultState, action) {
    switch(action.type) {
        case RECEIVE_EPISODE:
            return Object.assign({}, state, action.payload.episode);
        default:
            return state;
    }
}
