import { RECEIVE_VIDEO } from '../actions';

export const DEFAULT_VIDEO_JSON = {
    description: '',
    guid: { '#text': '' },
    link: '',
    image: '',
    pubDate: '',
    title: '',
    author: ''
};

let defaultState = DEFAULT_VIDEO_JSON;

export default function video(state = defaultState, action) {
    switch(action.type) {
        case RECEIVE_VIDEO:
            return Object.assign({}, state, action.payload.podcast);
        default:
            return state;
    }
}
