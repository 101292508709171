import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { receiveEpisode } from '../actions';
import { DEFAULT_EPISODE_JSON } from '../reducers/episode';
import { getGuid, getShowData } from '../js/helpers'

class PodcastPreviews extends React.Component {
    render() {
        const { episodes } = this.props;
       
        var episode = episodes[0] || DEFAULT_EPISODE_JSON;
            
        var showData = 
            episode.title.startsWith('Discussing Who', 0) ? getShowData('discussing-who') :
            episode.title.startsWith('Discussing Trek', 0) ? getShowData('discussing-trek') :
            episode.title.startsWith('Discussing Comics') ? getShowData('discussing-comics') :
            episode.title.startsWith('Techpedition') ? getShowData('techpedition') :
            episode.title.startsWith('Extremely Geek\'d Out') ? getShowData('extremely-geekd-out') :
            episode.title.startsWith('Fandom Squad') ? getShowData('fandom-squad') : 
            episode.title.startsWith('Terminus') ? getShowData('terminus') : getShowData('') ;

        var heroImage = showData.heroImages[Math.floor(Math.random() * showData.heroImages.length)];

        var backgroundStyle = { backgroundImage: 'url(' + heroImage + ')' };
        var title = { __html: episode.title };
        var duration = episode['itunes:duration'];
        var date = new Date(episode.pubDate).toLocaleDateString("en-us", {year: "numeric", month: "short", day: "2-digit"})
        
        var moreEpisodes = episodes.slice(1, 4).map(currentPodcast => {
            var title = { __html: currentPodcast.title };
            var duration = currentPodcast['itunes:duration'];
            var date = new Date(currentPodcast.pubDate).toLocaleDateString("en-us", {year: "numeric", month: "short", day: "2-digit"});

            return (                 
                <div key={getGuid()} className="row">
                    <div className="col-lg-12">
                        <div className="site-subheading">
                            <a href={currentPodcast.link} target="_blank" rel="noopener noreferrer" title={currentPodcast.title}>
                                <div className="episode-info">{date} {<span className="right"><i className="fa fa-clock-o" aria-hidden="true"></i> {duration} MINS</span>}</div>
                                <h1 dangerouslySetInnerHTML={title} />
                                <div className="time">{currentPodcast.pubDate}</div>               
                            </a>
                            <div className="read-more">
                                <Link onClick={() => this.props.receiveEpisode(currentPodcast)} to="">LISTEN NOW <i className="fa fa-play-circle" aria-hidden="true"></i></Link>
                                <a href={currentPodcast.link} target="_blank" rel="noopener noreferrer" title={currentPodcast.title}>READ MORE <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div> 
            );
        });

        console.log(showData.hosts);
        
        var hostLinks = showData.hosts.map(currentHost => {
            var hostLink = 'https://twitter.com/' + currentHost.handle;
            var hostImage = 'https://avatars.io/twitter/' + currentHost.handle + '/?size=large';
            // var name = currentHost.name; 
            // var handle = '@' + currentHost.handle;
            // var title = currentHost.title;

            return (     
                <div key={getGuid()} className="col-lg-4 d-none d-lg-block">    
                    <a href={ hostLink } target="_blank" rel="noopener noreferrer" title="Twitter"><img src={ hostImage } alt="" /></a>
                    {/* <em>{ title }</em>
                    <br/>{ name }
                    <br/><a href={ hostLink } target="_blank" rel="noopener noreferrer" title="Twitter">{ handle }</a> */}
                </div>   
            );
        });

        return (
            <div key={episode.id} className="intro-header">
                <div className="background" style={backgroundStyle}></div>
                <div className="background-overlay"></div>
                <div className="container">
                    <div className={'row ' + showData.class}>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                            <div className="podcast-heading">
                                <img src={showData.logo} alt={showData.name}/>
                                <div className="subtitle">{showData.tagline}</div>                                    
                                <div className="schedule"><i className="fa fa-microphone" aria-hidden="true"></i>
                                    Subscribe on <a href={showData.applePodcasts} target="_blank" rel="noopener noreferrer" title="Apple Podcasts">Apple</a>,&nbsp;
                                    <a href={showData.spotify} target="_blank" rel="noopener noreferrer" title="Spotify">Spotify</a>,&nbsp;
                                    or <a href={showData.googlePodcasts} target="_blank" rel="noopener noreferrer" title="Google Podcasts">Google</a>!
                                </div>
                                <div className="subscribe">											
                                    <a href={showData.applePodcasts} target="_blank" rel="noopener noreferrer" title="Apple Podcasts"><i className="fa fa-apple"></i></a>   
                                    <a href={showData.spotify} target="_blank" rel="noopener noreferrer"title="Spotify"><i className="fa fa-spotify"></i></a>                                 
                                    <a href={showData.googlePodcasts} target="_blank" rel="noopener noreferrer"title="Google Podcasts"><i className="fa fa-google"></i></a>
                                    <a href={showData.youtube} target="_blank" rel="noopener noreferrer"title="YouTube"><i className="fa fa-youtube"></i></a>
                                    <a href={showData.rss} target="_blank" rel="noopener noreferrer"title="Rss Feed"><i className="fa fa-rss"></i></a>						
                                </div>                                 
                                <div className="row hosts">
                                    { hostLinks }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="site-heading">
                                <div className="episode-info">{date} {<span className="right"><i className="fa fa-clock-o" aria-hidden="true"></i> {duration} MINS</span>}</div>
                                <a href={episode.link} target="_blank" rel="noopener noreferrer" title={episode.title}>
                                    <h1 dangerouslySetInnerHTML={title} />
                                </a>
                                <div className="time">{episode.pubDate}</div>                                
                                <div className="read-more">
                                    <Link onClick={() => this.props.receiveEpisode(episode)} to="">LISTEN NOW <i className="fa fa-play-circle" aria-hidden="true"></i></Link>
                                    <a href={episode.link} target="_blank" rel="noopener noreferrer" title={episode.title}>READ MORE <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></a>
                                </div>
                                {/* <a href={episode.link} target="_blank" rel="noopener noreferrer" title={episode.title}>
                                     <span className="subheading" dangerouslySetInnerHTML={description} />
                                </a> */}
                                {moreEpisodes}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect( null, { receiveEpisode } )(PodcastPreviews)
