import { combineReducers } from 'redux';
import episode from './episode';
import podcast from './podcast';
import video from './video';
import youtube from './youtube';

const rootReducer = combineReducers({
    episode, podcast, video, youtube
});

export default rootReducer;
