import React from 'react';
import DocumentMeta from 'react-document-meta';

import HeroPostPreviews from './components/HeroPostPreviews';
import HeroHeader from './components/HeroHeader';
// import { getShowData } from './js/helpers'

class Home extends React.Component {

  render() { 
    // var showData = getShowData('discussing-network');

    var title = 'Discussing Network';
    var keywords = 'discussing, network, doctor who, trek, star, who, star trek, comics, podcast, doctor, apple, spotify, google';
    var description = 'The Discussing Network features interviews, reviews, and news, with a focus on Doctor Who, Star Trek, ' + 
      'and Comics. We also tend to cover other geek-focused topics that we may find interesting. Discussing Network, covering ' + 
      'all things geek!';
    var image = 'https://www.discussingnetwork.com/AlbumArt-Export.jpg';
    var url = window.location.href; 
    var meta = {
        title: title,
        description: description,
        canonical: url,
        meta: {
            name: { keywords: keywords },
            itemProp: { name: title, description: description, image: image },
            property: {
                'og:type': 'website',
                'og:title': title,
                'og:site_name': 'Discussing Network',
                'og:url': url,
                'og:description': description,
                'og:local': 'en_US',
                'og:image': image,
                'og:image:width': '3000',
                'og:image:height': '3000',
                'og:image:secure_url': image,
                'og:image:type': 'image/png',
                'twitter:card': 'summary',
                'twitter:site': '@discussingnet',
                'twitter:title': title,
                'twitter:description': description,
                'twitter:image:src': image,
                'fb:app_id': '484190452283294',
            }
        },
        link: { rel: { publisher: 'https://plus.google.com/113012137470211057920/', image_src: image } },
        auto: { ograph: true }
    }; 

    return (
      <div>
        <DocumentMeta {...meta} extend />
        <HeroHeader showSlug={ 'discussing-network' } showFeedData={0} />
        <HeroPostPreviews /> 
      </div>
    );
  }
}

export default Home;